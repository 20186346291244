import React from "react";
import "./Projects.css";
import { motion } from "framer-motion"; // Import motion from framer-motion
import img1 from '../../assets/picture/HOME11.JPG';
import img2 from '../../assets/picture/Home.JPG';
import img3 from '../../assets/picture/blogging.JPG';
import img4 from '../../assets/picture/kacha.JPG';
import img5 from '../../assets/picture/cake.jpg';
import img6 from '../../assets/picture/todo.png';

const Projects = () => {
  // Define animation variants
  const cardVariants = {
    hidden: { opacity: 0, y: 20 }, // Initial state
    visible: { opacity: 1, y: 0 }, // State when visible
  };

  return (
    <div className="container project" id="projects">
      <h2 className="mt-3 mb-1 text-center text-uppercase">Top Recent Projects</h2>
      <hr />
      <p className="pb-3 text-center">
        I am a passionate MERN Stack Developer with experience working on diverse projects, including NGO platforms, eCommerce solutions, and professional portfolios. Through my work with NGOs, I’ve helped build user-friendly systems that facilitate donations, volunteer management, and community outreach.
      </p>

      <div className="row">
        {/* Using motion for card animation */}
        {[
          { img: img1, title: "Preserving Pakistan Heritage (E-Commerce)", badges: ["Node", "Express", "React", "MongoDB", "Vite", "Admin Panel", "Node Mailer"], link: "https://github.com/mehranulhassan/preservingPakistanHeritage", notifyBadge: "Full Stack" },
          { img: img2, title: "Help On Demand (HOD)", badges: ["Node.js", "React", "Express", "MongoDB", "Node Mailer"], link: "https://github.com/mehranulhassan/Hod", notifyBadge: "Full Stack NGO" },
          { img: img3, title: "Blogging App (Models & Migrations)", badges: ["Node", "Express", "NoSQL"], link: "https://github.com/mehranulhassan/blogging-app", notifyBadge: "Backend" },
          { img: img4, title: "Ecommerce Store (Kacha Bazar)", badges: ["Node", "Express", "React", "MongoDB", "Vite", "Admin Panel", "Node Mailer"], link: "https://kachabazar-store-nine.vercel.app/", notifyBadge: "Full Stack" },
          { img: img5, title: "Ecommerce Cake App (Sweet Cakes)", badges: ["React", "HTML", "CSS", "Bootstrap", "JavaScript"], link: "https://github.com/mehranulhassan/cakesapp", notifyBadge: "Frontend" },
          { img: img6, title: "To-do App", badges: ["React", "HTML", "CSS", "Bootstrap", "Node.js", "Models and Migrations"], link: "https://github.com/mehranulhassan/Todos-app", notifyBadge: "Frontend & Backend" },
        ].map((project, index) => (
          <motion.div
            className="col-md-4"
            key={index}
            variants={cardVariants} // Apply animation variants
            initial="hidden" // Initial state
            animate="visible" // Animate to visible state
            transition={{ duration: 0.5 }} // Transition duration
          >
            <div className="card rounded">
              <div className="card-image">
                <span className="card-notify-badge">{project.notifyBadge}</span>
                <img src={project.img} alt={project.title} />
              </div>
              <div className="card-image-overlay m-auto mt-3">
                {project.badges.map((badge, idx) => (
                  <span className="card-detail-badge" key={idx}>{badge}</span>
                ))}
              </div>
              <div className="card-body text-center">
                <h5 className="text-uppercase">{project.title}</h5>
                <a className="ad-btn" href={project.link}>View</a>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
