import React from "react";
import { useTheme } from "../../context/ThemeContext";
import Typewriter from "typewriter-effect";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import { motion } from "framer-motion";
import "./home.css";
import cv from '../../assets/docs/mehran.cv.pdf'; // Ensure this is the correct path to your CV

const Home = () => {
  const [theme, setTheme] = useTheme();
  
  // Handle theme toggle
  const handleTheme = () => {
    setTheme((prevState) => (prevState === "light" ? "dark" : "light"));
  };

  // Animation variants for Tada effect
  const tadaVariants = {
    initial: { scale: 1 },
    animate: { 
      scale: [1, 1.1, 1], // Scale to 1.1 and back to 1
      transition: { 
        duration: 0.5, // Duration of the animation
        ease: "easeInOut",
      }
    }
  };

  return (
    <div className="container-fluid home-container" id="home">
      <div 
        className="theme-btn" 
        onClick={handleTheme} 
        aria-label="Toggle Theme"
      >
        {theme === "light" ? (
          <BsFillMoonStarsFill size={30} />
        ) : (
          <BsFillSunFill size={30} />
        )}
      </div>
      <div className="container home-content">
        <motion.div 
          variants={tadaVariants}
          initial="initial"
          animate="animate"
        >
          <h2>Hi 👋 I'm Mehran ul Hassan</h2>
          <h1>
            <Typewriter
              options={{
                strings: [
                  "FullStack Developer!",
                  "Mern Stack Developer!",
                  "Front End Developer!",
                  "Back End Developer!",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </h1>
        </motion.div>
        <motion.div 
          variants={tadaVariants}
          initial="initial"
          animate="animate"
          transition={{ duration: 0.5 }} // Optional: Make this transition different if desired
        >
          <div className="home-buttons">
            <a
              className="btn btn-hire"
              href="https://api.whatsapp.com/send?phone=923037190868"
              rel="noreferrer"
              target="_blank"
              aria-label="Hire Mehran"
            >
              Hire Me
            </a>
            <a 
              className="btn btn-cv" 
              href={cv} 
              download="Mehran_ul_Hassan_Resume.pdf" // Provide the desired filename here
              aria-label="Download Resume"
            >
              My Resume
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
