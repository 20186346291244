import React from "react";
import { motion } from "framer-motion";
import Layout from "./components/Layout/Layout";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Education from "./pages/Educations/Education";
import Projects from "./pages/Projects/Projects";
import Techstack from "./pages/Techstack/Techstack";
import WorkExp from "./pages/workExp/WorkExp";
import ScrollToTop from "react-scroll-to-top";
import { useTheme } from "./context/ThemeContext";
import MobileNav from "./components/MobileNav/MobileNav";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [theme] = useTheme();

  // Define the animation variants for the Tada effect
  const tadaVariants = {
    initial: { scale: 1 },
    animate: { 
      scale: [1, 1.1, 1], // Scale to 1.1 and back to 1
      transition: { 
        duration: 0.5, // Duration of the animation
        ease: "easeInOut",
      }
    }
  };

  return (
    <div id={theme}>
      <ToastContainer />
      <MobileNav />
      <Layout />
      <div className="container">
        <About />
        <Education />
        <Techstack />
        <Projects />
        <WorkExp />
        <Contact />
      </div>
      <motion.div 
        variants={tadaVariants}
        initial="initial"
        animate="animate"
        className="footer pb-3 ms-3"
      >
        <h4 className="text-center">Design by 😍 Mehran ul Hassan &copy; 2024</h4>
      </motion.div>
      <ScrollToTop
        smooth
        color="#f29f67"
        style={{ backgroundColor: "#1e1e2c", borderRadius: "80px" }}
      />
    </div>
  );
}

export default App;
